<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="LayersIcon" size="19"/>
          <h4 class="mb-0 ml-50">Demirbaş Tedarikçi Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="İsim" rules="required">
              <b-form-group label="Ünvan">
                <b-form-input trim placeholder="Ünvan" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Telefon Numarası">
              <b-form-group label="Telefon Numarası">
                <b-form-input trim placeholder="Telefon Numarası" v-model="dataInfo.phoneNumber" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="E Posta">
              <b-form-group label="E Posta">
                <b-form-input trim placeholder="E Posta" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Ülke" rules="required">
              <b-form-group label="* Ülke" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.countryId" :options="countryOptions" :reduce="val => val.value" :clearable="false" v-on:input="getCities"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Şehir" rules="required">
              <b-form-group label="Şehir" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.cityId" :options="cityOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Açık Adres">
              <b-form-group label="Açık Adres">
                <b-form-textarea trim placeholder="Açık Adres" v-model="dataInfo.address" :state="getValidationState(validationContext)" rows="3"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea v-model="dataInfo.description" rows="3" placeholder="Açıklama..." :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Durum" rules="required">
              <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <action-buttons :back-route="'asset-supplier-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/asset/supplier/store"
import addressModule from "@/store/common/address"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {statusOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";

export default {
  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ADDRESS_MODULE_NAME = 'store-address'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ADDRESS_MODULE_NAME, addressModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ADDRESS_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      name: '',
      description: '',
      countryId: null,
      cityId: null,
      phoneNumber: null,
      email: null,
      address: null,
      isActive: true,
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        getCities(dataInfo.value.countryId)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'asset-supplier-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'asset-supplier-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const countryOptions = ref([])
    const cityOptions = ref([])

    busy.value = true
    store.dispatch('store-address/countryList').then(response => {
      response.data.data.forEach((value, index) => {
        countryOptions.value.push({label: value.name, value: value.id})
      });

      if (router.currentRoute.params.id === undefined) {
        getCities(dataInfo.value.countryId)
      }

    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const getCities = (val) => {
      cityOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/cityList', {country: val}).then(response => {
          response.data.data.forEach((value, index) => {
            cityOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.cityId = null
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      countryOptions,
      cityOptions,

      statusOptions,

      getCities,
      onSubmit,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
